.form {
  composes: form-base from '../../shared/forms.module.css';
}

.form-input-group {
  composes: form-input-group from '../../shared/forms.module.css';
}

.form {
  @apply pr-4 text-sm flex flex-wrap justify-between;

  fieldset {
    @apply w-full;
  }

  > fieldset {
    &:not(:first-child) {
      @apply mt-4;
    }

    legend {
      @apply mb-4 text-sm;
    }
  }

  :global(.fieldsetArmUsed1),
  :global(.fieldsetAdditionalReading) {
    legend {
      @apply text-base font-medium;
    }
  }

  :global(.fieldsetBloodPressure1),
  :global(.fieldsetBloodPressure2) {
    @apply flex gap-4;

    .form-input-group {
      @apply flex-1;
    }
  }

  :global(.fieldsetTimestampDate1),
  :global(.fieldsetTimestampTime1),
  :global(.fieldsetTimestampDate2),
  :global(.fieldsetTimestampTime2) {
    width: calc(50% - 8px);
  }

  :global(.MuiSlider-thumb) {
    @apply shadow-none !important;
  }
}

.form-control {
  .label {
    @apply leading-normal mb-2 text-sm;
    @apply text-textPrimary !important;
  }

  .group {
    @apply flex-no-wrap pl-4;

    :global(.MuiButtonBase-root) {
      padding: 6px;
    }

    :global(.MuiFormControlLabel-label) {
      @apply text-sm;
    }
  }
}

.slider {
  .label {
    @apply leading-normal mb-2 text-sm;
    @apply text-textPrimary !important;
  }

  .group {
    @apply flex flex-no-wrap gap-4 items-center justify-between pl-2;
  }

  .range-label {
    @apply font-light leading-normal text-right text-sm w-2/12 whitespace-no-wrap;
  }
}
