.form {
  composes: form-base from '../../shared/forms.module.css';
}

.form-input-group {
  composes: form-input-group from '../../shared/forms.module.css';
}

.form {
  @apply pr-4 text-sm;

  > fieldset {
    &:not(:first-child) {
      @apply mt-4;
    }

    :first-child > div {
      :global(.MuiFormLabel-root) {
        @apply font-medium text-base;
      }
    }

    legend {
      @apply font-medium mb-4 text-base;
    }
  }

  :global(.MuiSlider-thumb) {
    @apply shadow-none !important;
  }
}

.form-control {
  .label {
    @apply leading-normal mb-2 text-sm;
    @apply text-textPrimary !important;
  }

  .group {
    @apply flex-no-wrap pl-4;

    :global(.MuiButtonBase-root) {
      padding: 6px;
    }

    :global(.MuiFormControlLabel-label) {
      @apply text-sm;
    }
  }
}

.period-label {
  @apply block leading-normal mb-2 text-sm;
}

.slider {
  .label {
    @apply leading-normal mb-2 text-sm;
    @apply text-textPrimary !important;
  }

  .group {
    @apply flex flex-no-wrap gap-4 items-center justify-between pl-2;
  }

  .range-label {
    @apply font-light leading-normal text-right text-sm w-2/12 whitespace-no-wrap;
  }
}

.stepper {
  padding: 0 !important;

  :global(.MuiStep-horizontal) {
    padding-left: 0px !important;
  }
}
