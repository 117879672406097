.form-base {
  &:not(:global(.was-validated)) {
    :global {
      .MuiFormControl-root {
        .MuiFormLabel-root {
          @apply text-textSecondary;
        }

        .MuiInput-underline {
          &:before,
          &:after {
            @apply border-b border-textSecondary;
          }
        }

        .MuiOutlinedInput-root {
          .MuiOutlinedInput-notchedOutline {
            @apply border-textSecondary;
          }

          &.Mui-error:hover .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
          }
        }

        &:hover {
          .MuiFormLabel-root {
            @apply text-textPrimary;
          }

          .MuiInput-underline:after {
            @apply border-textPrimary;
          }

          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              @apply border-textPrimary;
            }
          }
        }
      }
    }
  }

  &:global(.was-validated) {
    :global {
      .MuiInput-underline:not(.Mui-error):after {
        @apply border-b border-textPrimary;
      }

      .MuiOutlinedInput-root:not(.Mui-error) {
        .MuiOutlinedInput-notchedOutline {
          @apply border border-textSecondary;
        }

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
          @apply border-secondaryLight;
        }

        &:hover .MuiOutlinedInput-notchedOutline {
          @apply border-textPrimary;
        }
      }

      .MuiFormHelperText-root.Mui-error {
        @apply block leading-snug;

        font-size: 0.8125rem; /* ~13px */
      }
    }
  }
}

.form-input-group {
  @apply mb-5;
}

.btn-cancel {
  @apply border border-primary btn-rounded btn-sm px-2 text-primary text-sm;

  min-width: 8rem;

  &:focus,
  &:hover {
    @apply bg-primaryDark border-primaryDark text-white;

    outline-color: theme('colors.primaryDark');
  }

  &:disabled,
  &:global(.disabled) {
    @apply border-textSecondary cursor-auto pointer-events-none text-textSecondary;
  }
}

.btn-submit {
  @apply btn btn-primary btn-rounded btn-sm font-medium px-2 text-sm;

  min-width: 10rem;

  &:focus,
  &:hover {
    @apply bg-primaryDark border-primaryDark text-white;

    outline-color: theme('colors.primaryDark');
  }

  > :global(.MuiCircularProgress-root) {
    @apply h-3 -ml-5 mr-2 w-3;

    color: inherit;
  }

  &:disabled,
  &:global(.disabled) {
    @apply cursor-auto pointer-events-none text-textSecondary;

    background-color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
  }
}

.btn-icon {
  @apply bg-center bg-no-repeat bg-transparent border border-textDisabled cursor-pointer min-w-0 px-4 py-2 rounded select-none text-primary;

  &:before {
    content: '\00a0';
  }

  > span {
    @apply sr-only;
  }

  &:disabled {
    @apply cursor-auto pointer-events-none;

    background-color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
    filter: grayscale(1);
  }
}

.input-file {
  @apply absolute overflow-hidden p-0;

  height: 0.1px;
  width: 0.1px;
  z-index: -1;

  + label {
    @apply btn-icon;

    background-image: url('../../assets/images/icon-attachment.min.svg');

    > span {
      @apply sr-only;
    }
  }

  &:disabled + label,
  &:disabled + label:hover,
  &:disabled + label:focus {
    @apply cursor-auto text-secondary;

    background-color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
    filter: grayscale(1);
  }
}
