.root {
  :global(.MuiPaper-root) {
    @apply bg-white text-black shadow-lg;

    :global(.MuiAlert-icon) {
      @apply text-blueLagoon;
    }

    :global(.MuiAlert-message) {
      .action {
        @apply flex gap-2 mt-2;
      }
    }
  }
}

.btn-root {
  min-width: 0;

  @apply py-1 px-6 text-xs font-normal;
}

.btn-cancel {
  composes: btn-cancel from '../../shared/forms.module.css';

  @apply btn-root border-danger text-danger;

  &:focus,
  &:hover {
    @apply bg-danger border-danger;

    outline-color: theme('colors.danger');
  }
}

.btn-submit {
  composes: btn-submit from '../../shared/forms.module.css';

  @apply btn-root bg-blueLagoon border-blueLagoon;

  &:focus,
  &:hover {
    @apply bg-blueLagoon border-blueLagoon;

    outline-color: theme('colors.blueLagoon');
  }
}

.btn-outline-close {
  composes: btn-submit from '../../shared/forms.module.css';

  @apply btn-root bg-transparent border-blueLagoon text-blueLagoon;

  &:focus,
  &:hover {
    @apply bg-blueLagoon border-blueLagoon text-white;

    outline-color: theme('colors.blueLagoon');
  }
}

@screen sm {
  .root {
    max-width: 420px;
  }
}
