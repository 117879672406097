@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

@tailwind base;

html,
body {
  @apply bg-white font-sans;
}

html,
body,
#root {
  @apply h-full w-full m-0 overflow-x-hidden overflow-y-hidden p-0;
}

@media print {
  html,
  body,
  #root {
    @apply h-auto overflow-y-visible !important;
  }

  .MuiDialog-root,
  .uwy,
  [class*='userway'] {
    @apply hidden !important;
  }
}

@tailwind components;

.container {
  max-width: none;
}

.container-fluid {
  max-width: theme(screens.md);
}

.Popover {
  z-index: 1400;
}

.Popover-body {
  @apply inline-flex bg-primary text-white flex-col p-3;

  border-radius: 0.3rem;
  width: 20rem;

  p {
    @apply mb-4;
  }
}

.Popover-tipShape {
  fill: theme('colors.primary');
}

.MuiBadge-badge.MuiBadge-anchorOriginBottomRightCircle {
  bottom: 15%;
  right: 15%;

  .MuiAvatar-img {
    @apply h-4 w-4;
  }
}

@media screen and (display-mode: window-controls-overlay) {
  .MuiBackdrop-root,
  .MuiDrawer-paper {
    top: env(titlebar-area-height, 0) !important;
  }
  
  .MuiSnackbar-anchorOriginTopCenter {
    top: calc(env(titlebar-area-height, 0) + 8px) !important;
  }

  .MuiDialog-paper {
    margin-top: calc(env(titlebar-area-height, 0) + 32px) !important;
  }
}

@tailwind utilities;
