.sidebar {
  @apply bg-athensGray flex-col flex-no-wrap hidden overflow-y-auto py-8;

  max-width: theme('width.sidebar');
  min-width: theme('width.sidebar');

  .ul {
    @apply flex flex-1 flex-col list-none;

    padding-top: 0.875rem;

    + .ul {
      @apply pt-0;

      padding-bottom: 0.875rem;
    }
  }

  .anchor {
    @apply align-middle flex flex-no-wrap px-8 py-3 text-sm text-textPrimary w-full;

    > img {
      @apply align-middle flex-none mr-3 self-center w-5;

      max-height: theme('height.5');
    }

    &:global(.active) {
      @apply font-bold text-primary;
    }

    &:hover {
      @apply bg-geyser;
    }

    .span-primary {
      @apply flex-none overflow-hidden whitespace-no-wrap;

      max-width: 11.25rem;
      text-overflow: ellipsis;
    }

    .span-secondary {
      @apply flex-1 text-right;
    }
  }

  .li {
    min-width: 13rem;

    > .ul {
      @apply pt-0 ml-8;

      .anchor {
        @apply pl-8 py-3;

        .span-primary {
          max-width: 7.25rem;
        }
      }
    }
  }

  .version {
    @apply mt-auto text-center text-dustyGray text-xs;
  }
}

@screen md {
  .sidebar {
    @apply flex;
  }
}

@media print {
  .sidebar {
    @apply hidden !important;
  }
}
