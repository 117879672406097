.page {
  @apply flex-1 py-16;

  > :global(.container-fluid) {
    @apply mx-auto px-gutter-1/2;
  }
}

.alert {
  @apply col;

  > .title {
    @apply h1 leading-normal mb-4;
  }

  > .body {
    p {
      @apply mb-4;

      &:first-child {
        @apply font-medium text-base;
      }
    }
  }
}

@screen sm {
  .alert {
    @apply col-8 offset-2;
  }
}

@media screen and (display-mode: standalone), screen and (display-mode: window-controls-overlay) {
  .alert > .title {
    @apply text-center text-white;
  }
}
