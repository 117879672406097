.form {
  composes: form-base from '../../shared/forms.module.css';
}

.form-input-group {
  composes: form-input-group from '../../shared/forms.module.css';
}

.form {
  @apply pr-4 text-sm;

  > fieldset {
    &:not(:first-child) {
      @apply mt-4;
    }

    legend {
      @apply font-medium mb-4;
    }
  }

  :global(.MuiSlider-thumb) {
    @apply shadow-none !important;
  }

  :global(.fieldsetSymptoms) {
    :global(.MuiButtonBase-root) {
      font-size: 2rem;
      padding: 6px;

      :hover {
        @apply text-primary;
      }

      :focus {
        @apply text-primary;
      }
    }

    :global(.MuiFormControlLabel-label) {
      @apply sr-only;
    }
  }

  :global(.fieldsetHeight),
  :global(.fieldsetWeight) {
    > legend {
      @apply mb-0;
    }

    span {
      @apply text-sm;
    }
  }

  :global(.fieldsetSymptoms),
  :global(.fieldsetBloodPressure),
  :global(.fieldsetHeartRate),
  :global(.fieldsetPulseOximeter) {
    > .form-input-group {
      @apply pl-2;
    }
  }
}

.radio {
  .group {
    @apply flex-no-wrap pl-4;
  }

  .group-label {
    @apply mb-2 leading-normal text-sm;
    @apply text-textPrimary !important;
  }

  .icon {
    padding: 6px;
  }

  .label {
    @apply font-light text-sm;

    > :global(.MuiFormControlLabel-label) {
      @apply text-sm;
    }
  }
}

.slider {
  .group {
    @apply flex flex-no-wrap gap-4 items-center justify-between;
  }

  .label {
    @apply mb-2 leading-normal text-sm;
    @apply text-textPrimary !important;

    margin-left: -7px;
  }

  .range-label {
    @apply font-light leading-normal text-sm whitespace-no-wrap text-right w-2/12;
  }
}

@screen sm {
  .form {
    :global(.fieldsetSymptoms) :global(.MuiButtonBase-root) {
      font-size: 2.5rem;
      padding: 6px;
    }

    :global(.fieldsetHeight),
    :global(.fieldsetWeight) {
      @apply inline-block mr-6 w-4/12;
    }
  }
}
