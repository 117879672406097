.root {
  @apply max-w-screen-md mx-auto my-6 overflow-y-auto w-full;

  > :global(.row) {
    @apply max-w-screen-xs flex-col mx-auto px-gutter-1/2;
  }
}

.form-sign-in,
.form-verify-mfa {
  composes: form-base from '../../shared/forms.module.css';
}

.heading1 {
  @apply h1 text-center mb-2;
}

.leadText {
  @apply lead text-center mb-12;
}

.btn-cancel {
  composes: btn-cancel from '../../shared/forms.module.css';

  @apply mb-3 mt-5 w-full;
}

.btn-submit {
  composes: btn-submit from '../../shared/forms.module.css';

  @apply mb-5 w-full;
}

.action-forgot-password {
  @apply font-medium text-sm text-primary text-center w-full;

  &:global(.disabled) {
    @apply text-textDisabled;
  }
}

.wrapper-action-resend-code {
  @apply mb-5 whitespace-no-wrap;

  > .before,
  > .after {
    @apply text-secondary;
  }

  > .before {
    @apply mr-1;
  }

  > .after {
    @apply ml-1;
  }

  > :global(.MuiCircularProgress-root) {
    @apply ml-1;
  }
}

.action-resend-code {
  @apply text-primary;

  &:global(.disabled) {
    @apply text-textDisabled;
  }
}

@media screen and (display-mode: standalone) {
  .root {
    @apply mt-auto;
  }
}

@media screen and (display-mode: standalone), screen and (display-mode: window-controls-overlay) {
  .root {
    @apply flex flex-col flex-no-wrap h-full max-w-none m-0;

    > :global(.row) {
      @apply bg-white m-auto p-6 rounded shadow-2xl w-full;

      max-width: 552px; /* 600px - (24px margin * 2) */
    }
  }

  .heading1 {
    @apply text-4xl;
  }
}

@media screen and (display-mode: standalone) and (max-width: 599.98px),
  screen and (display-mode: window-controls-overlay) and (max-width: 599.98px) {
  .root > :global(.row) {
    @apply mx-6 my-auto w-auto;
  }
}

@media print {
  .root {
    @apply hidden !important;
  }
}
