@import '~ag-grid-community/dist/styles/ag-grid.min.css';
@import '~ag-grid-community/dist/styles/ag-theme-material.min.css';

.ag-cell-primary-dark {
  @apply text-primaryDark;
}

.ag-cell-strong {
  @apply font-bold;
}

/**
 * Materia theme overrides
 */
.ag-theme-material {
  @apply font-base text-sm text-secondary;

  .ag-header-cell,
  .ag-cell {
    @apply border-0 pl-gutter-1/2 pr-0;
  }

  .ag-header-cell-resize {
    width: 8px;

    &:after {
      @apply absolute bg-alto block;

      content: '';
      z-index: 2;
      left: calc(50% - 1px);
      width: 2px;
      height: 30%;
      top: 35%;
    }
  }

  .ag-ltr .ag-cell {
    @apply border-0;
  }

  .ag-cell[role='gridcell'] * {
    @apply overflow-x-hidden overflow-y-hidden whitespace-no-wrap;

    text-overflow: ellipsis;
  }

  .ag-header-cell:first-of-type:hover,
  .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing,
  .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover {
    @apply bg-transparent;
  }

  .ag-row {
    @apply border-0;

    &:not(.ag-row-last),
    &.ag-row-first.ag-row-last {
      @apply border-b border-alto;
    }
  }

  .ag-row-hover {
    @apply bg-geyser;
  }

  .ag-icon-checkbox-checked,
  .ag-checkbox-input-wrapper.ag-checked:after {
    @apply text-primary;
  }
}
