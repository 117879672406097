.root {
  @apply block text-sm;
}

.content {
  composes: sidebar from '../layout.module.css';
}

.ul {
  composes: ul from '../layout.module.css';
}

.li {
  composes: li from '../layout.module.css';
}

.anchor {
  composes: anchor from '../layout.module.css';
}

.li > .anchor,
.li > .anchor:global(.active) {
  @apply text-white;

  &:hover {
    @apply bg-transparent;
  }

  > img {
    filter: grayscale(2) brightness(2);
  }
}

.card:global(.MuiCard-root) {
  @apply bg-primary flex-1 pb-8 px-4 rounded-none text-white overflow-y-auto;

  background: linear-gradient(90deg, rgba(0, 116, 166, 1) 0%, rgba(0, 131, 143, 1) 100%);

  .header {
    @apply py-0;
  }

  > .header {
    @apply py-4;
  }

  .title {
    @apply font-black leading-6 text-xl;

    letter-spacing: 0.22em;
  }

  .action {
    @apply cursor-pointer my-auto py-4;
  }

  .content {
    @apply bg-transparent block px-0 pt-0 !important;

    .ul {
      @apply pt-0;
    }
  }

  :global(.MuiCardHeader-root),
  :global(.MuiCardHeader-content) {
    @apply overflow-x-hidden overflow-y-hidden whitespace-no-wrap;
  }

  :global(.MuiCardHeader-content) {
    @apply cursor-pointer flex flex-row flex-no-wrap items-center py-4;

    :global(.MuiAvatar-root) {
      @apply text-base;
    }
  }

  .username {
    @apply block mx-4 overflow-x-hidden overflow-y-hidden whitespace-no-wrap;

    max-width: theme('width.40');
    text-overflow: ellipsis;
  }
}

.menu-group {
  &:last-child > a {
    @apply block p-4;
  }

  &:not(:last-child) {
    @apply border-b overflow-x-hidden overflow-y-hidden whitespace-no-wrap;

    border-bottom-color: rgba(255, 255, 255, 0.3);
  }
}
