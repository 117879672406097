.root {
  :global {
    .MuiDialogContent-root {
      @apply py-4;
    }

    .MuiDialogActions-root {
      @apply flex-col items-stretch;
    }

    .MuiDialogActions-spacing > :not(:first-child) {
      @apply ml-0 mt-2;
    }
  }
}

.action-reject {
  composes: btn-cancel from '../forms.module.css';
}

.action-accept {
  composes: btn-submit from '../forms.module.css';
}

@screen sm {
  .root {
    :global {
      .MuiDialogActions-root {
        @apply flex-row items-center;
      }

      .MuiDialogActions-root > :not(:first-child) {
        @apply ml-2 mt-0;
      }
    }
  }
}
