.dialog {
  composes: root from './shared/dialog/dialog-box.module.css';
}

.app-logo {
  composes: logo from './layout/app-header.module.css';
}

.dialog.new-version-available {
  z-index: 2147483647 !important;

  .app-logo {
    @apply bg-primary mr-3 p-2 rounded;
  }

  .body > p {
    &.note1 {
      @apply mt-6 text-danger text-sm tracking-wider;
    }

    &.note2 {
      @apply mb-8 mt-3 text-sm tracking-wider;
    }
  }
}
