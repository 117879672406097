:global(#sm-route-title) {
  @apply flex-1 font-light text-white tracking-wider;
}

.root {
  @apply bg-primary px-gutter-1/2 relative text-white;

  background: linear-gradient(90deg, rgba(0, 116, 166, 1) 0%, rgba(0, 131, 143, 1) 100%);

  > :global(.container-fluid) {
    @apply flex flex-row flex-no-wrap items-center max-w-screen-sm mx-auto py-3;
  }
}

.logo {
  @apply flex-1 font-black leading-6 text-white whitespace-no-wrap;

  letter-spacing: 0.22em;
}

.a11y {
  @apply sr-only;
}

.ul {
  composes: ul from './app-layout.module.css';
}

.anchor {
  composes: anchor from './app-layout.module.css';
}

.menu-button {
  @apply flex flex-row flex-no-wrap items-center ml-gutter-1/2 overflow-y-hidden pb-1;

  :global(.MuiAvatar-root) {
    @apply inline-flex text-base;
  }

  > span:last-child {
    @apply ml-2 overflow-x-hidden overflow-y-hidden whitespace-no-wrap;

    max-width: theme('width.40');
    text-overflow: ellipsis;
  }
}

.menu-item:global(.MuiMenuItem-root) {
  @apply p-0 text-sm;

  a {
    @apply flex-1 pl-4 pr-8 py-3;
  }

  img {
    @apply inline-block mr-4 w-4;
  }
}

@media (min-width: 768px) {
  .anchor {
    &[data-action-id='sendGuestAccountInvitation'] {
      @apply bg-auto bg-no-repeat border text-xs;

      background-image: url('../../assets/images/action-invite-patient.min.svg');
      background-position: 12px center;
      border-color: rgba(255, 255, 255, 0.3);
      border-radius: 4px;
      line-height: 1rem;
      padding: 6px 12px 6px 32px;
    }
  }

  .language-toggle {
    @apply ml-3;
  }
}

@screen md {
  .root {
    @apply px-10;
  }
}

@media screen and (min-width: theme(screens.md)) and (display-mode: standalone),
  screen and (min-width: theme(screens.md)) and (display-mode: window-controls-overlay) {
  :global(.root) > .root {
    @apply px-gutter;
  }
}

@media screen and (display-mode: standalone), screen and (display-mode: window-controls-overlay) {
  :global(.titlebar-area) {
    @apply absolute bg-primaryDark;

    -webkit-app-region: drag;
    app-region: drag;
    left: env(titlebar-area-x, 0);
    height: env(titlebar-area-height, 33px);
    top: env(titlebar-area-y, 0);
    width: env(titlebar-area-width, 100%);
  }

  .logo {
    @apply h-full text-center;

    line-height: env(titlebar-area-height, 33px);
  }

  .ul {
    @apply ml-auto;
  }
}

@media screen and (display-mode: window-controls-overlay) {
  :global(.root) > .root {
    margin-top: env(titlebar-area-height, 33px);
  }
}

@media print {
  .root {
    @apply hidden !important;
  }
}
