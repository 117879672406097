.root {
  @apply btn btn-sm btn-rounded flex-1 font-medium h-full text-white text-xs;
  @apply bg-blueLagoon;

  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  min-width: 11rem;
}

button.root {
  @apply flex flex-row flex-no-wrap p-0;

  > span {
    @apply block border-r border-textDisabled flex-1 leading-loose py-2;
  }

  > svg {
    @apply block h-full px-1 w-8;
  }
}
