.root {
  @apply h-64;
}

.root,
.list-item {
  @apply text-secondary;
}

.list-item-group:global(.MuiTreeItem-group) {
  @apply mx-4;
}

.list-item-label {
  font-weight: inherit;
  color: inherit;
}

.label-root {
  @apply flex flex-no-wrap items-center;
}

.label-icon {
  @apply mr-1;
}

.label-text {
  @apply overflow-x-hidden overflow-y-hidden whitespace-no-wrap;

  text-overflow: ellipsis;
}