.dialog-title {
  @apply flex flex-row flex-no-wrap items-center;

  > div {
    @apply col-equal;

    &:first-child {
      @apply overflow-x-hidden overflow-y-hidden whitespace-no-wrap;

      text-overflow: ellipsis;
    }
  }

  > :global(.MuiFormControl-root) {
    @apply ml-gutter;
  }
}

.dialog-content {
  @apply block;
}
