.nav {
  @apply flex list-none;

  > .item {
    > .link {
      @apply block mx-0 my-2 px-gutter-1/2 py-2;
    }

    &:last-child > .link {
      @apply pr-0;
    }
  }
}
