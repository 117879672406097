.root {
  @apply flex items-center justify-center relative;

  font-size: 62.5%;

  :global {
    .MuiButtonGroup-root {
      @apply absolute bottom-0 mb-4 mx-4;

      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.23);
      z-index: 2;

      button {
        @apply cursor-pointer text-black;

        background-color: rgba(255, 255, 255, 0.75);

        &:last-child {
          @apply text-danger;
        }
      }
    }
  }

  .info-container {
    @apply absolute h-full w-full;

    z-index: 2;
  }

  .info-row-bottom {
    @apply flex;
  }

  .identity {
    @apply inline-flex items-center m-1 overflow-x-hidden p-1 rounded text-white;

    background-color: rgba(0, 0, 0, 0.5);

    span {
      @apply overflow-x-hidden whitespace-no-wrap;

      text-overflow: ellipsis;
    }

    svg {
      @apply ml-1 text-sm;
    }
  }

  .inner-container {
    @apply h-full w-full;
  }

  .avatar-container {
    @apply absolute bg-black flex inset-0 items-center justify-center;

    z-index: 1;

    :global(.MuiAvatar-root) {
      @apply border-2 border-primary h-48 text-6xl w-48;
    }
  }

  &:not(.main-participant) {
    @apply bg-black h-meetingRoomMobileSidebar mb-0 mr-2 overflow-x-hidden overflow-y-hidden rounded;

    border: theme('width.meetingRoomParticipantBorder') solid rgb(245, 248, 255);
    padding-top: calc(theme('height.meetingRoomMobileSidebar') - theme('width.meetingRoomParticipantBorder'));
    width: calc(calc(theme('height.meetingRoomMobileSidebar') * 16) / 9);

    video {
      @apply object-contain;

      filter: none;
    }

    .info-container {
      @apply bg-transparent flex flex-col justify-between top-0;
    }

    .info-row-bottom {
      @apply absolute bottom-0 justify-between left-0 right-0;
    }

    .identity {
      @apply flex m-0 px-1 py-0 rounded-none;
    }

    .inner-container {
      @apply absolute left-0 top-0;
    }

    .avatar-container :global(.MuiAvatar-root) {
      @apply h-10 text-sm w-10;
    }
  }
}

@screen sm {
  .root {
    :global(.MuiButtonGroup-root) {
      @apply right-0;
    }

    &:not(.main-participant) {
      @apply h-0 mb-2 mr-0;

      /* 56.25 = (9 / 16) * 100 */
      padding-top: calc(56.25% - theme('width.meetingRoomParticipantBorder'));
    }
  }
}
