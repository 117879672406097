.form {
  composes: form-base from '../../shared/forms.module.css';
}

.form-input-group {
  composes: form-input-group from '../../shared/forms.module.css';
}

.form {
  @apply text-sm;

  fieldset:not(:first-child) {
    @apply mt-8;
  }

  legend {
    @apply mb-4;
  }
}

.radio {
  &:global(.MuiFormControl-root) {
    @apply flex flex-row items-center gap-2;
  }

  .group {
    @apply flex flex-no-wrap flex-shrink-0 gap-1;

    :global(.MuiFormControlLabel-root) {
      @apply m-0;
    }
  }

  .group-label {
    @apply leading-normal text-sm flex-shrink-0;
    @apply text-textPrimary !important;
  }

  .icon {
    &:global(.MuiButtonBase-root) {
      @apply appearance-none outline-none w-8 h-8 p-0 rounded-md text-black bg-geyser;

      &:hover {
        @apply bg-geyser;
      }
    }

    &:global(.Mui-checked) {
      @apply text-white bg-havelockBlue;

      &:hover {
        @apply bg-havelockBlue;
      }
    }

    > span {
      @apply text-xs font-medium;
    }
  }
}
