.root {
  @apply row;
  @apply bg-athensGray flex-no-wrap items-center mx-0 py-3 z-sticky;

  border-bottom: solid theme('borderWidth.default') rgb(0 0 0 / 8%);
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 8%);
  max-height: theme('height.20');
}

.institute {
  @apply col-6 h1 overflow-x-hidden overflow-y-hidden whitespace-normal;
  @apply font-normal text-xs;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.form {
  composes: form-base from '../../shared/forms.module.css';
}

.form {
  @apply col-6 ml-auto;

  max-width: theme(screens.sm);
  min-width: 33vw;

  > fieldset {
    @apply bg-white;
  }
}

@screen sm {
  .institute {
    @apply pl-6;

    font-size: clamp(0.75rem, calc(0.25rem + 1vmin), 1.5rem);
  }
  
  .form {
    @apply pr-6;
  }
}

@screen md {
  .institute {
    font-size: clamp(0.875rem, calc(0.25rem + 1vmin), 1.5rem);
  }
}

@screen lg {
  .institute {
    font-size: clamp(1rem, calc(0.25rem + 1vmin), 1.5rem);
  }
}

@media print {
  .root {
    @apply hidden !important;
  }
}
