.root {
  @apply mt-auto text-primary text-xs;

  > :global(.container-fluid) {
    @apply border-t border-silver flex flex-col flex-no-wrap items-center mx-auto py-5;
  }
}

.ul {
  @apply flex flex-row flex-no-wrap justify-around items-center list-none mb-5 px-gutter-1/2 text-xs w-full;
}

.anchor {
  @apply block whitespace-no-wrap mr-4;
}

.copyright {
  @apply font-bold text-center text-doveGray whitespace-no-wrap;
}

@screen sm {
  .root > :global(.container-fluid) {
    @apply flex-row items-start;
  }

  .ul {
    @apply flex-1 justify-end w-auto;
  }

  .copyright {
    @apply pr-gutter-1/2;
  }
}

@media (min-width: 768px) {
  .anchor {
    @apply mx-3;
  }
}

@media print {
  .root {
    @apply hidden !important;
  }
}

@media screen and (display-mode: standalone), screen and (display-mode: window-controls-overlay) {
  .root {
    @apply text-white;
  }

  .copyright {
    @apply text-white;
  }
}
