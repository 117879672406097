.form {
  composes: form-base from '../../shared/forms.module.css';
}

.form-input-group {
  composes: form-input-group from '../../shared/forms.module.css';
}

.form {
  @apply text-sm;

  fieldset:not(:first-child) {
    @apply mt-8;
  }

  legend {
    @apply mb-6;
  }
}

.radio {
  .group {
    @apply ml-3;
  }

  .group-label {
    @apply mb-2 leading-normal text-sm;
    @apply text-textPrimary !important;
  }

  .icon {
    padding: 6px;
  }

  .label {
    @apply font-light text-sm;
  }
}
