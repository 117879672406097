.form {
  composes: form-base from '../shared/forms.module.css';

  @apply max-w-screen-sm -mx-gutter-1/2 mt-6;

  > fieldset > legend {
    @apply mb-2 px-gutter-1/2 text-sm;
  }
}

.form-input-group {
  composes: form-input-group from '../shared/forms.module.css';

  @apply col;
}

.action-generate {
  composes: action-reject from '../shared/dialog/dialog-box.module.css';

  @apply border-secondaryLight text-secondaryLight w-full;

  > :global(.MuiCircularProgress-root) {
    @apply h-3 -ml-5 mr-2 w-3;

    color: inherit;
  }
}

@screen sm {
  .form {
    > fieldset {
      @apply flex flex-row flex-no-wrap items-start;

      .form-input-group {
        @apply col-equal;
      }
    }
  }
}
