.grid:global(.ag-theme-material) {
  @apply border border-alto h-64 text-xs;

  :global {
    .ag-header-cell,
    .ag-cell {
      line-height: 44px;
    }  

    .ag-header-cell {
      @apply text-secondary;
    }

    .ag-cell {
      @apply text-secondaryLight;

      em.span-primary,
      em.span-secondary {
        @apply block leading-none mt-2 not-italic;
      }

      em.span-primary {
        @apply mt-2;
      }

      em.span-secondary {
        @apply mb-2 mt-1;
      }
    }
  }
}
