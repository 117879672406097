.root,
:global(.root) {
  @apply flex flex-col flex-no-wrap h-full m-0 overflow-x-hidden overflow-y-hidden p-0 w-full;

  max-width: theme('width.screen');
}

.root > header {
  @apply mb-auto px-gutter-1/2;
}

:global(.root) > header > :global(.container-fluid) {
  max-width: none;

  :global(.MuiIconButton-root) {
    @apply ml-0 mr-5 p-0;
  }
}

.ul {
  @apply flex flex-row flex-no-wrap justify-end items-center list-none text-sm;
}

.anchor {
  @apply block whitespace-no-wrap mr-4;
}

.dialog-app-offline {
  composes: root from '../shared/dialog/dialog-box.module.css';
}

.dialog-app-offline {
  z-index: 2147483647 !important;

  .content {
    @apply p-3 m-0 !important;
  }

  .alert {
    @apply flex-col items-center px-8 py-16 leading-7 text-base tracking-wider;
  }

  .alert-icon {
    @apply text-6xl;
  }
}

.dialog-billing-file {
  .action-accept {
    @apply p-2;

    min-width: 13rem;
  }

  p {
    @apply text-secondaryLight;
  }

  table {
    @apply text-sm w-full;

    :global(.MuiCircularProgress-root) {
      @apply h-5 w-5 !important;
      @apply ml-auto;
    }
  }

  tr {
    @apply border-alto border-b;
  }

  th {
    @apply text-left;
  }

  td {
    @apply text-right;
  }

  th,
  td {
    @apply pr-2 pt-2;
  }
}

.empty-screen {
  @apply min-h-screen flex items-center justify-center;
}

.exit-button {
  @apply text-primaryDark font-medium;
}

@screen sm {
  .dialog-app-offline .alert {
    @apply flex-row;
  }
}

@media (min-width: 768px) {
  .anchor {
    @apply mx-3;
  }
}

@media screen and (display-mode: standalone), screen and (display-mode: window-controls-overlay) {
  .root {
    background: linear-gradient(90deg, rgba(0, 116, 166, 1) 0%, rgba(0, 131, 143, 1) 100%);

    > [class^='CircularProgress'] > :global(.MuiCircularProgress-root) {
      @apply text-white;
    }

    > header > :global(.container-fluid) {
      @apply py-0;
    }
  }
}

@media print {
  .root,
  :global(.root) {
    @apply block;

    min-height: auto;
  }
}
