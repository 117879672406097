/* purgecss start ignore */
.root {
  @apply border-l border-alto flex flex-1 flex-col flex-no-wrap overflow-y-auto p-4;
}

.contact-list-option {
  :global {
    .MuiAutocomplete-optionAvatar .MuiAvatar-root {
      @apply text-base;
    }

    .MuiAutocomplete-optionLabel {
      @apply flex-1 overflow-x-hidden overflow-y-hidden pl-4;
    }

    .MuiAutocomplete-optionLabelPrimaryText,
    .MuiAutocomplete-optionLabelSecondaryText {
      @apply block max-w-full overflow-x-hidden overflow-y-hidden whitespace-no-wrap;

      text-overflow: ellipsis;
    }

    .MuiAutocomplete-optionLabelPrimaryText {
      @apply font-normal text-secondary;
    }

    .MuiAutocomplete-optionLabelSecondaryText {
      @apply font-light italic text-sm text-textSecondary;
    }
  }
}

.contact-list-tag:global(.MuiAutocomplete-tag) {
  @apply rounded;

  :global(.MuiAvatar-root) {
    @apply h-6 ml-2 rounded text-xs w-6;
  }
}

.document-list {
  @apply border-b border-alto mb-3 pb-2 overflow-x-hidden overflow-y-auto;

  max-height: 3.5rem;
  max-width: calc(theme('width.screen') - theme('padding.8'));

  :global(.MuiChip-root) {
    @apply max-w-full mr-3 mt-2 rounded text-xs;
  }
}

.action-bar {
  @apply bg-mystic flex flex-row flex-no-wrap max-w-none -mb-4 -mx-4 px-4 py-3 relative;

  min-height: 40px;

  > .col {
    @apply flex flex-1 flex-no-wrap;

    &.left {
      @apply pr-4;
    }

    &.right {
      @apply justify-end;

      > :not(:last-child) {
        @apply mr-4;
      }
    }
  }
}

.btn-submit {
  composes: btn-submit from '../shared/forms.module.css';

  min-width: 7rem;

  + input + label {
    @apply ml-4 py-0;
  }
}

.btn-close-message {
  composes: btn-icon from '../shared/forms.module.css';

  @apply py-0;

  background-image: url('../../assets/images/action-close-message-enabled.min.svg');
  background-size: 17px;
}

@screen sm {
  .root {
    @apply p-6;
  }

  .document-list {
    @apply mb-4;

    max-height: 6rem;
    max-width: calc(theme('width.screen') - theme('padding.16'));

    :global(.MuiChip-root) {
      font-size: 0.8125rem;
    }
  }

  .action-bar {
    @apply justify-between m-0 mt-6 p-0;

    background: theme('backgroundColor.transparent');

    > .col {
      @apply flex-initial;
    }
  }
}

@screen md {
  .document-list {
    max-width: calc(calc(theme('width.screen') - theme('width.sidebar')) - theme('padding.16'));
  }
}

@media print {
  .root {
    @apply hidden !important;
  }
}
/* purgecss end ignore */
