.label:global(.MuiInputLabel-root) {
  @apply hidden;
}

.input {
  @apply ml-auto;

  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 54px;

  &.expanded {
    @apply w-full;
  }
}

.icon-label > span {
  @apply sr-only;
}
