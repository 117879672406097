.root {
  @apply flex overflow-x-auto;

  background-color: rgb(79, 83, 85);
  grid-area: 2 / 1 / 3 / 3;
  z-index: 5;
}

.scroll-container {
  @apply flex justify-center;
}

.inner-scroll-container {
  @apply flex p-meetingRoomMobileSidebar;
}

@screen sm {
  .root {
    @apply block overflow-y-auto;

    grid-area: 1 / 2 / 1 / 3;
    overflow-x: initial;
  }

  .inner-scroll-container {
    @apply block px-0;
  }
}
