.root {
  :global {
    .MuiDialogTitle-root {
      @apply bg-mercury px-4 py-2;

      min-height: theme('spacing.10');

      div {
        @apply overflow-x-hidden whitespace-no-wrap;

        text-overflow: ellipsis;
      }
    }

    .MuiDialogContent-root {
      @apply bg-black flex flex-col flex-no-wrap mb-0 p-0;

      min-height: theme('maxHeight.meetingRoomVideo');
    }
  }
}

.room {
  @apply flex-1 grid h-full relative;

  --total-sidebar-height: calc(
    theme('height.meetingRoomMobileSidebar') +
      calc(calc(theme('padding.meetingRoomMobileSidebar') * 2) + theme('width.meetingRoomParticipantBorder'))
  );

  grid-template-columns: 100%;
  grid-template-rows: calc(100% - var(--total-sidebar-height)) var(--total-sidebar-height);
}

@screen sm {
  .room {
    grid-template-columns: 1fr theme('width.meetingRoomSidebar');
    grid-template-rows: 100%;
  }
}
