.root {
  @apply flex flex-1 flex-col flex-no-wrap;
}

.action-compose-message {
  composes: root from '../new-message-menu-button.module.css';
}

.action-compose-message,
button.action-compose-message {
  @apply mb-4 mr-gutter-1/2;
}

button.action-compose-message {
  @apply relative;

  > svg {
    @apply absolute inset-y-0 right-0;
  }

  > span {
    @apply flex-initial;

    bottom: theme('spacing.2');
    right: theme('spacing.8');
    top: theme('spacing.2');
    width: calc(100% - theme('width.8'));
  }
}

.grid-view {
  @apply flex-1;
}

.message-view {
  @apply border-l border-alto flex flex-1 flex-col flex-no-wrap p-4;
}

.action-save-draft {
  composes: action-reject from '../../shared/dialog/dialog-box.module.css';

  @apply border-primaryDark text-primaryDark;
}

.emr-upload-status {
  @apply text-sm;

  span {
    @apply inline-block mt-1;
  }

  strong {
    @apply font-medium pb-2;
  }
}

@screen sm {
  .action-compose-message,
  button.action-compose-message {
    @apply mb-0;
  }

  .message-view {
    @apply p-8;
  }

  .action-save-draft {
    @apply mr-auto;
  }
}

@media print {
  .root {
    @apply overflow-y-auto;
  }

  .grid-view {
    @apply hidden !important;
  }

  .message-view {
    @apply border-0 !important;
  }
}
